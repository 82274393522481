import React, { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import { useParams } from "react-router-dom";
import CircularProgress from '@mui/material/CircularProgress';
import { useNavigate, useSearchParams } from "react-router-dom";
import Select from 'react-select';

import prevArrow from '../Assets/Images/prev-arrow.svg';
import nextArrow from '../Assets/Images/next-arrow.svg';

import '../App.scss';

const jobTitleValues = [
  { value: "Other", label: "Other" },
  { value: "Advertisement", label: "Advertisement" },
  { value: "Agent's web site", label: "Agent's web site" },
  { value: "Billboard", label: "Billboard" },
  { value: "Craigslist", label: "Craigslist" },
  { value: "Direct Mail", label: "Direct Mail" },
  { value: "Email", label: "Email" },
  { value: "Existing Customer Referral", label: "Existing Customer Referral" },
  { value: "Facebook", label: "Facebook" },
  { value: "Family", label: "Family" },
  { value: "Flyer", label: "Flyer" },
  { value: "For Sale Sign", label: "For Sale Sign" },
  { value: "Friend", label: "Friend" },
  { value: "FSBO", label: "FSBO" },
  { value: "Google", label: "Google" },
  { value: "Lending Tree", label: "Lending Tree" },
  { value: "Newspaper", label: "Newspaper" },
  { value: "Open House", label: "Open House" },
  { value: "Past Client", label: "Past Client" },
  { value: "Personal Referral", label: "Personal Referral" },
  { value: "Postcard", label: "Postcard" },
  { value: "Real Estate Book", label: "Real Estate Book" },
  { value: "Realtor.com", label: "Realtor.com" },
  { value: "Relocation", label: "Relocation" },
  { value: "Research", label: "Research" },
  { value: "Sales Representative", label: "Sales Representative" },
  { value: "Search Engine", label: "Search Engine" },
  { value: "Seller", label: "Seller" },
  { value: "Trulia", label: "Trulia" },
  { value: "Website", label: "Website" },
  { value: "Yard Sign", label: "Yard Sign" },
  { value: "Yellow Pages", label: "Yellow Pages" },
  { value: "Zillow", label: "Zillow" },
];

const Details = (props) => {

  let navigate                        = useNavigate();
  const { id }                        = useParams();
  const [searchParams, setSearchParams] = useSearchParams();

  const [isSaving, setIsSaving]       = React.useState(false);
  const [isLoading, setIsLoading]       = React.useState(false);
  const [isFormValid, setIsFormValid] = React.useState(false);
  const [isMember, setIsMember]       = React.useState(true);

  const [firstName, setFirstName]     = React.useState("");
  const [lastName, setLastName]       = React.useState("");
  const [email, setEmail]             = React.useState("");
  const [jobTitle, setJobTitle]       = React.useState("");
  const [jobTitleMember, setJobTitleMember]       = React.useState("");
  const [churchName, setChurchName]   = React.useState("");
  const [schoolName, setSchoolName]   = React.useState("");
  const [zipCode, setZipCode]         = React.useState("");
  const [firstNameError, setFirstNameError]       = React.useState(false);
  const [lastNameError, setLastNameError]         = React.useState(false);
  const [emailError, setEmailError]               = React.useState(false);
  const [jobTitleError, setJobTitleError]         = React.useState(false);
  const [churchNameError, setChurchNameError]     = React.useState(false);
  const [schoolNameError, setSchoolNameError]     = React.useState(false);
  const [zipCodeError, setZipCodeError]           = React.useState(false);

  useEffect(() => {
    if(searchParams.get("member") != null) {
      getUserDetails();
    } else {
      setIsMember(false);
    }
    // document.body.style.backgroundColor = "#122845";
  }, []);

  const emailRegex = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;


  const getUserDetails = () => {
    setIsLoading(true);
  
    fetch(process.env.REACT_APP_API_URI + '/v1/user/' + searchParams.get("member"), {
      method: 'GET',
      mode: 'cors',
      headers: { 'Content-Type': 'application/json' }
    }).then(response => {
      return response.json();
    }).then(data => {
      if(data.status == "success") {
        setIsMember(true);
        setFirstName(data.firstName);
        setLastName(data.lastName);
        setEmail(data.email);
        // setJobTitle("none");
        setJobTitle("");
        setIsLoading(false);
      } else {
        // setOpenSnackbar(true);
      }
    });
  }

  const updateFirstName = (event) => {
    setFirstName(event.target.value);
    checkFormValid();
  }

  const updateLastName = (event) => {
    setLastName(event.target.value);
    checkFormValid();
  }

  const updateEmail = (event) => {
    setEmail(event.target.value);
    checkFormValid();
  }

  const updateJobTitle = (selectedOption) => {
    // console.log(event.target.value);
    console.log(selectedOption.value);
    setJobTitle(selectedOption.value);
    checkFormValid();
  }
  
  const updateChurchName = (event) => {
    setChurchName(event.target.value);
    checkFormValid();
  }

  const updateZipCode = (event) => {
    setZipCode(event.target.value);
    checkFormValid();
  }

  const checkFormValid = () => {
    if(isMember) {
      console.log(firstName);
      console.log(jobTitle);
      // console.log(jobTitleMember);
      if(firstName != "" && lastName != "") {
        console.log("We get here");
        setIsFormValid(true);
      } else {
        setIsFormValid(false);
      }
    } else {
      if(firstName != "" && lastName != "" && email != "" && emailRegex.test(email) && jobTitle != "" && churchName != "" && zipCode.length > 3 && zipCode.length < 13) {
      // if(firstName != "" && lastName != "" && email != "" && emailRegex.test(email) && jobTitle != "" && schoolName != "" && zipCode.length > 3 && zipCode.length < 13) {
        setIsFormValid(true);
      } else {
        setIsFormValid(false);
      }
    }
  }

  const showErrors = () => {
    if(firstName == "") {
      setFirstNameError(true);
      setTimeout(() => { console.log(firstNameError); }, 2000);
    }
    if(lastName == "") {
      setLastNameError(true);
    }
    if(!emailRegex.test(email)) {
      setEmailError(true);
    }
    // if(jobTitle == "none" || !jobTitle) {
    if(jobTitle == "" || !jobTitle) {
      setJobTitleError(true);
    }
    // if(schoolName == "") {
    //   setSchoolNameError(true);
    // }
    if(churchName == "") {
      setChurchNameError(true);
    }
    if(zipCode.length < 4) {
      setZipCodeError(true);
    }
  }

  const submitToApi = () => {
    
    let apiCall         = '/v1/assessment/' + id + '/leader';
    if(isMember) {
      apiCall           = '/v1/assessment/' + id + '/member/' + searchParams.get("member");
    }

    let jsonBody        = JSON.stringify({
      details: {
        "firstName": firstName,
        "lastName": lastName,
        "email": email,
        "jobTitle": jobTitle,
        "churchName": churchName,
        "zipCode": zipCode
      },
      formVersion: 2
    });

    fetch(process.env.REACT_APP_API_URI + apiCall, {
      method: 'PUT',
      mode: 'cors',
      headers: { 'Content-Type': 'application/json' },
      body: jsonBody
    }).then(response => {
      return response.json();
    }).then(data => {
      if(data.status == "success") {
        navigate("/results/" + id, { replace: true });
      } else {
        // setOpenSnackbar(true);
      }
    });

  }

  const handleSubmit = () => {
    setIsSaving(true);
    console.log("Runnign");


    if(isMember && firstName != "" && lastName != "" && jobTitle != "") {
      submitToApi();
    } else if(!isMember && firstName != "" && lastName != "" && emailRegex.test(email) && jobTitle != "none" && churchName != "" && zipCode.length > 3 && zipCode.length < 13) {
    // } else if(!isMember && firstName != "" && lastName != "" && emailRegex.test(email) && jobTitle != "none" && schoolName != "" && zipCode.length > 3 && zipCode.length < 13) {
      submitToApi();
    } else {
      showErrors();
      setIsSaving(false);
    }

  }

  const showButton = () => {
    if(isSaving) {
      return <div className="button-progress"><CircularProgress style={{'color': '#fff'}} size={23} /></div>
    }
    return "SHOW MY RESULTS";
  }

  const showHintText = (field) => {
    if(field == "firstName") {
      if(firstNameError) {
        return <div className="error-text">Please confirm your first name.</div>
      }
    }
    if(field == "lastName") {
      if(lastNameError) {
        return <div className="error-text">Please confirm your last name.</div>
      }
    }
    if(field == "email") {
      if(emailError) {
        return <div className="error-text">Please enter a valid email address.</div>
      }
    }
    if(field == "jobTitle") {
      if(jobTitleError) {
        return <div className="error-text">Please select an option.</div>
      }
    }
    // if(field == "schoolName") {
    //   if(schoolNameError) {
    //     return <div className="error-text">Please confirm your school name.</div>
    //   }
    // }
    if(field == "churchName") {
      if(churchNameError) {
        return <div className="error-text">Please confirm your phone number.</div>
      }
    }
    if(field == "zipCode") {
      if(zipCodeError) {
        return <div className="error-text">Please confirm your ZIP code.</div>
      }
    }
  }

  const removeHintText = (field, e) => {

    // change active input field title color 
    if(e.target.closest(".form-detail")) {
      document.body.querySelectorAll(".input-title").forEach(elm => elm.style.color = "#3B3A3A");
      e.target.closest(".form-detail").querySelector(".input-title").style.color = "#3B3A3A";
    }

    if(field == "firstName") {
      setFirstNameError(false);
    }
    if(field == "lastName") {
      setLastNameError(false);
    }
    if(field == "email") {
      setEmailError(false);
    }
    if(field == "jobTitle") {
      setJobTitleError(false);
    }
    // if(field == "schoolName") {
    //   setSchoolNameError(false);
    // }
    if(field == "churchName") {
      setChurchNameError(false);
    }
    if(field == "zipCode") {
      setZipCodeError(false);
    }
  }

  const prevQuestion = () => {
    // navigate("/assessment-additional/" + id, { replace: true });
    if(isMember) {
      navigate('/assessment/' + id + '/review?member=' + searchParams.get("member"), { replace: true });
    } else {
      navigate('/assessment/' + id + '/review', { replace: true });
    }
  };

  if (isLoading) {
		return <div className="container details loading"><CircularProgress  style={{'color': '#F7D54D'}} size={60} /></div>
	}
  
  return (
    <div className="container details">
      
      <div className="details-container">
        
        <h1>You're all done!</h1>
        <div style={{ height: '10px' }}></div>
        <p>Before we get to your report, please confirm<br/>your details so that we can email you a copy.</p>
        {/* <p className="mandatory-field-text">All fields are required.</p> */}

        <div className="form">

          <div className="form-detail">
            <p className="input-title">First name<span className="required-field">*</span></p>
            <TextField required hiddenLabel placeholder="Bob" value={firstName} onFocus={(e) => removeHintText("firstName", e)} onChange={(e) => {updateFirstName(e)}} fullWidth variant="standard" size="normal" InputProps={{ disableUnderline: true }} className="form-details-text" />
            {showHintText("firstName")}
          </div>

          <div className="form-detail">
            <p className="input-title">Last name<span className="required-field">*</span></p>
            <TextField required hiddenLabel placeholder="Byers" value={lastName} onFocus={(e) => removeHintText("lastName", e)}  onChange={(e) => {updateLastName(e)}} fullWidth variant="standard" size="normal" InputProps={{ disableUnderline: true }} className="form-details-text" />
            {showHintText("lastName")}
          </div>

          <div className="form-detail">
            <p className="input-title">Email<span className="required-field">*</span></p>
            <TextField required hiddenLabel placeholder="robertb@hotmail.com" disabled={isMember} value={email} onFocus={(e) => removeHintText("email", e)} onChange={(e) => {updateEmail(e)}} fullWidth variant="standard" size="normal" InputProps={{ disableUnderline: true }} className="form-details-text" />
            {showHintText("email")}
          </div>

          {
            !isMember ? (
              <div className="form-detail">
                <p className="input-title">Phone<span className="required-field">*</span></p>
                <TextField required hiddenLabel placeholder="+(845) 838-2135" value={churchName} onFocus={(e) => removeHintText("churchName", e)} onChange={(e) => {updateChurchName(e)}} fullWidth variant="standard" size="normal" InputProps={{ disableUnderline: true }} className="form-details-text" />
                {showHintText("churchName")}
              </div>
            ) : null
          }
          
          {/* {
            !isMember ? (
              <div className="form-detail school-name">
                <p className="input-title">School name<span className="required-field">*</span></p>
                <TextField hiddenLabel placeholder="Hill Crest School" value={schoolName} onFocus={(e) => removeHintText("schoolName", e)} onChange={(e) => {updateSchoolName(e)}} fullWidth variant="standard" size="normal" InputProps={{ disableUnderline: true }} className="form-details-text" />
                {showHintText("schoolName")}
              </div>
            ) : null
          } */}
          
          {
            !isMember ? (
              <div className="form-detail">
                <p className="input-title">ZIP code<span className="required-field">*</span></p>
                <TextField hiddenLabel required
                  type="text" 
                  placeholder="11678" 
                  className="form-details-text" 
                  value={zipCode} 
                  onFocus={(e) => removeHintText("zipCode", e)} 
                  onChange={(e) => {updateZipCode(e)}} 
                  fullWidth variant="standard" size="normal" 
                  InputProps={{ disableUnderline: true }} 
                  onInput = {(e) => {
                    return e.target.value = e.target.value?.toString().slice(0,12)
                  }}
                />
                {showHintText("zipCode")}
              </div>
            ) : null
          }

          <div className="form-detail">
            <p className="input-title">How did you find us?<span className="required-field">*</span></p>

            {/* <TextField hiddenLabel placeholder="Board Member" value={jobTitle} onFocus={(e) => removeHintText("jobTitle", e)} onChange={(e) => {updateJobTitle(e)}} fullWidth variant="standard" size="normal" InputProps={{ disableUnderline: true }} className="form-details-text" /> */}

            {/* <TextField select hiddenLabel value={jobTitle} onFocus={(e) => removeHintText("jobTitle", e)} onChange={(e) => {updateJobTitle(e)}} fullWidth variant="standard" size="normal" InputProps={{ disableUnderline: true }} className="form-details-text">
              <MenuItem key={0} value="none" disabled>Please select an option below</MenuItem>
              {jobTitleValues.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.value}
                </MenuItem>
              ))}
            </TextField> */}

            {/* Added react-select instead of MUI TextField */}
            <Select classNamePrefix="react-select" className="form-details-text react-select" 
              options={jobTitleValues} 
              onFocus={(e) => removeHintText("jobTitle", e)}
              onChange={updateJobTitle} 
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary25: '#21165A33',
                  primary: '#21165A',
                  neutral0: '#F4F4F4',
                  neutral50: '#B5B5B5', // placeholder text color
                },
              })}
              placeholder={"Craigslist"} />
            {showHintText("jobTitle")}
          </div>


        </div>
          <div className="details-buttons">
            <div className="back-btn">
              <Button onClick={prevQuestion}>
                <img src={prevArrow} alt="previous button" />&nbsp;&nbsp;
              </Button>
            </div>

            <div className="cta-btn">
              <Button onClick={handleSubmit} disabled={isSaving}>
                {showButton()}
              </Button>
            </div>
          </div>

      </div>
      
    </div>
  );
  
}
export default Details;