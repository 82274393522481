import { useState, useRef, useEffect } from 'react';

import { Link, useSearchParams } from "react-router-dom";

import useWindowDimensions from '../Hooks/useWindowDimensions';
import VideoThumbnail from '../Assets/Images/video-thumbnail.png';
import PlayButton from '../Assets/Images/play-button.png';
import PlayButtonHover from '../Assets/Images/play-button-hover.png';
import Clock from '../Assets/Images/clock.png';
import House from '../Assets/Images/house.png';

import '../App.scss';

const StartImageOnly = props => {
  const { windowWidth }                   = useWindowDimensions();
  const [isLoading, setIsLoading]         = useState(true);

  const [searchParams, setSearchParams]   = useSearchParams();
  const [playingVideo, setPlayingVideo]   = useState(false);
  const [startPlaying, setStartPlaying]   = useState(false);
  const [playBtnImg, setPlayBtnImg]       = useState(PlayButton);
  searchParams.get("leader");
  const vidRef = useRef(null);

  // useEffect(() => { 
		// document.body.style.backgroundColor = "#122845";
	// },[]);

  const assessmentLink = () => {
    if(searchParams == "") {
      return "/assessment";
    } else {
      return "/assessment?" + searchParams;
    }
  }

  const playVideo = (e) => {
    console.log(vidRef.current);
    // vidRef.current.style.width = "70%";
    // console.log(document.getElementsByClassName("intro")[0]);
    vidRef.current.play();
    setPlayingVideo(true);
    if(!startPlaying) {
      setStartPlaying(true);
    }
  }

  const pauseVideo = (e) => {
    console.log(vidRef.current);
    vidRef.current.pause();
    setPlayingVideo(false);
  }

  const handleVideoPlaybackEnd = () => {
    setPlayingVideo(false);
    showThumbnail();
    showPlayButton();
  }

  const showThumbnail = () => {
    if(playingVideo) {
      return <img className="thumbnail" src={VideoThumbnail} alt="thumbnail" style={{ opacity: '0'}} onClick={(e) => pauseVideo(e)} />;
    } else {
      if(startPlaying) {
        return null
      } else {
        return <img className="thumbnail" src={VideoThumbnail} alt="thumbnail" />
      }
    }
  }

  const showPlayButton = () => {
    if(playingVideo) {
      return null
    } else {
      return <img className="playbtn" src={playBtnImg} 
      onMouseEnter={() => {console.log("mouse entered"); setPlayBtnImg(PlayButtonHover)}} onMouseLeave={() => { console.log("mouse left"); setPlayBtnImg(PlayButton)}} 
      
      onClick={(e) => playVideo(e)} alt="play button icon" />
    }
  }
  
  return (
    <div className="container start2">
      <div className="video">
        {/* <video ref={vidRef} onEnded={handleVideoPlaybackEnd}>
          <source
            src="https://40parables-assets.s3.amazonaws.com/Bleat-real-good-estate-assessment.mp4"
            type="video/mp4"
            />
        </video>
        {showThumbnail()}
        {showPlayButton()} */}
        <img src={House} alt="house" />
      </div>
      <div className="intro">
        <h1>Can your dream home become a reality?</h1>
        <h2>
        Take this assessment and help us match you with the perfect house that you can call home!
        </h2>
        <div className="completion">
          <Link to={assessmentLink()} className="cta">
            Get Started
          </Link>
          <div className='time-estimate'>
            <img className='clock-icon' src={Clock} alt="" /> <span>3 min</span>
          </div>
        </div>
        {/* <div className="completion">Estimated time for completion: 3 min</div> */}
      </div>
    </div>
  );
}
export default StartImageOnly;
